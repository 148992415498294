import React from "react";
import styled from "styled-components";
import { getInitials } from "@sussex/react-kit/utils";

const Badge = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  background-color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: ${({ theme }) => theme.fontSize.small};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 21px;
  }
`;

export default function InitialsBadge({ name }) {
  if (!name || name.length < 1) {
    return null;
  }
  return <Badge>{getInitials(name)}</Badge>;
}
