import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import useVideoContext from "../../hooks/useVideoContext";
import useMainSpeaker from "../../hooks/useMainSpeaker";
import Participant from "../Participant/";
import ParticipantInfo from "../ParticipantInfo";
import { useSessionMenuContext } from "../../providers/SessionMenuProvider";

const ParticipantWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1 1 auto;
  margin: auto;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.dark};

  & > div {
    width: 100%;
    height: 100%;
  }

  & video {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100%;
  }
`;

// Video positioning rules:
//    window is portrait, video is portrait: contain
//    window is portrait, video is landscape: set container to 16/9 aspect ratio and cover
//    window is landscape, video is portrait: contain
//    window is landscape, video is landscape: cover
const VideoWrapper = styled(ParticipantWrapper)`
  max-width: 1800px;

  @media (orientation: portrait) {
    & video {
      object-fit: contain;
    }

    ${({ videoOrientation }) =>
      videoOrientation === "landscape" &&
      css`
        flex: 0;
        position: relative;
        padding-top: 56%;

        & > div {
          position: absolute;
          top: 0%;
          right: 0;
          bottom: 0%;
          left: 0;
        }

        & video {
          object-fit: cover;
        }
      `};
  }

  @media (orientation: landscape) {
    & video {
      object-fit: ${({ videoOrientation }) =>
        videoOrientation === "portrait" ? "contain" : "cover"};
    }
  }
`;

const ScreenShareWrapper = styled(ParticipantWrapper)`
  background: ${({ theme }) => theme.colors.default};

  & video {
    width: 95%;
    object-fit: contain;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & video {
      /* Prevent video from covering the secondary participant list */
      ${({ participantListVisible }) =>
        participantListVisible &&
        css`
          width: calc(100% - 300px);
          margin-left: 25px;
        `}
    }
  }
`;

// Don't hide participant info for XS landscape video.
// prettier-ignore
const InfoWrap = styled.div`
  ${({ hideParticipantInfo }) =>
    hideParticipantInfo &&
    css`
      display: none;
    `};
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletInt - 1}px) and (orientation: portrait) {
    ${({ videoOrientation, hideParticipantInfo }) =>
      videoOrientation === "landscape" &&
      hideParticipantInfo &&
      css`
        display: block;
      `};
  }
`;

export default function MainParticipant({ isTherapist, hideParticipantInfo }) {
  const [video, setVideo] = useState(null);
  const [videoOrientation, setVideoOrientation] = useState(null);
  const mainParticipant = useMainSpeaker();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const screenTrack = Array.from(mainParticipant.tracks.values()).find(track =>
    track.trackName.includes("screen"),
  );
  const isScreenshare = typeof screenTrack !== "undefined";
  const { participantListVisible } = useSessionMenuContext();
  const Wrapper = isScreenshare ? ScreenShareWrapper : VideoWrapper;

  useEffect(() => {
    if (!video) return;
    const measureVideo = () => {
      setVideoOrientation(
        video.videoHeight >= video.videoWidth ? "portrait" : "landscape",
      );
    };
    video.addEventListener("loadedmetadata", measureVideo);
    return () => {
      video.removeEventListener("loadedmetadata", measureVideo);
    };
  }, [video]);

  return (
    <Wrapper
      videoOrientation={videoOrientation}
      participantListVisible={participantListVisible}
    >
      <div>
        <Participant
          participant={mainParticipant}
          isMain={true}
          setVideo={setVideo}
          enableScreenShare={true}
          showAdminControls={
            isTherapist && mainParticipant !== localParticipant
          }
          isScreenshare={isScreenshare}
          full
        >
          <InfoWrap
            hideParticipantInfo={hideParticipantInfo}
            videoOrientation={videoOrientation}
          >
            <ParticipantInfo participant={mainParticipant} />
          </InfoWrap>
        </Participant>
      </div>
    </Wrapper>
  );
}
