import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { isMobile } from "@sussex/react-kit/utils";

import useCopy from "../../hooks/useCopy";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useSessionMenuContext } from "../../providers/SessionMenuProvider";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
import useVideoContext from "../../hooks/useVideoContext";
import usePIP from "../../hooks/usePIP";
import copyIcon from "../../assets/copy.svg";
import PIPIcon from "../../assets/pip.svg";
import settingsIcon from "../../assets/audio-video-settings.svg";
import fullscreenIcon from "../../assets/fullscreen-icon.svg";
import participantListIcon from "../../assets/slide.svg";
import screenshareIcon from "../../assets/screenshare-icon.svg";
import stopScreenshareIcon from "../../assets/screenshare-active-icon.svg";
import {
  canFullscreen,
  isFullscreen,
  requestFullscreen,
  exitFullscreen,
} from "./fullscreenUtils";

const Wrapper = styled.nav`
  border-radius: 3px;
  background: white;
  padding: 30px;
`;

const ListGroup = styled.ul`
  margin: 0;
  padding: 0 0 25px 0;
  position: relative;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    padding: 25px 0 0 0;
  }
`;

const ListItem = styled.li`
  padding: 0 15px 0 35px;
  cursor: pointer;
  white-space: nowrap;
  list-style: none;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 22px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 50%;
      cursor: default;
    `};

  & + & {
    margin-top: 25px;
  }

  &:before {
    position: absolute;
    content: " ";
    left: 0;
    width: 22px;
    height: 22px;
    background-image: url(${({ icon }) => icon});
    background-size: contain;
    background-repeat: no-repeat;
    ${({ flipIcon }) =>
      flipIcon &&
      css`
        transform: rotate(180deg);
      `};
  }
`;

const SettingsMenu = ({ isTherapist }) => {
  const {
    setMenuOpen,
    setChangingAudioVideoSettings,
    participantListVisible,
    setParticipantListVisible,
  } = useSessionMenuContext();
  const { room, toggleScreenShare, isScreenSharing, screenShareParticipant } =
    useVideoContext();
  const { analyticsEvent } = useAnalyticsContext();

  const [isCopied, setIsCopied] = useState(false);

  const { pipEnabled, startPIP, hasPIP, stopPIP } = usePIP();
  const menuRef = useRef(null);
  const [
    audioVideoCopy,
    participantListVisibleCopy,
    participantListHiddenCopy,
    fullscreenText,
    exitFullscreenText,
    copyToClipboardText,
    copiedToClipboardText,
    screenshareText,
    stopScreenshareText,
    screenshareInUseText,
    enablePIP,
    disablePIP,
  ] = useCopy([
    "settingsMenu.audioVideo",
    "settingsMenu.participantList.visible",
    "settingsMenu.participantList.hidden",
    "settingsMenu.fullscreen",
    "settingsMenu.exitFullscreen",
    "settingsMenu.copyWaitingRoomLink",
    "settingsMenu.copiedToClipboard",
    "settingsMenu.screenshare",
    "settingsMenu.stopScreenshare",
    "settingsMenu.screenshareInUse",
    "settingsMenu.enablePIP",
    "settingsMenu.disablePIP",
  ]);

  const { getRoomUrl } = useWaitingRoomContext();

  const screenshareInUse =
    screenShareParticipant && screenShareParticipant !== room.localParticipant;
  const canScreenShare =
    !isMobile &&
    navigator.mediaDevices &&
    navigator.mediaDevices.getDisplayMedia;

  useEffect(() => {
    if (!menuRef.current) return;
    const clickOffClose = e => {
      if (
        menuRef.current &&
        menuRef.current !== e.target &&
        !menuRef.current.contains(e.target)
      ) {
        e.stopPropagation();
        setMenuOpen(false);
      }
    };
    window.addEventListener("click", clickOffClose, true);
    return () => {
      window.removeEventListener("click", clickOffClose, true);
    };
  }, [setMenuOpen]);

  const openMediaSettings = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "audio-video-settings",
    });
    setChangingAudioVideoSettings(true);
    setMenuOpen(false);
  };

  const handleFullscreenClick = () => {
    const isFull = isFullscreen();
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: isFull ? "disable-fullscreen" : "enable-fullscreen",
    });

    if (isFull) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }

    setMenuOpen(false);
  };

  const handleToggleThumbnailsClick = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: participantListVisible
        ? "hide-video-thumbnail"
        : "show-video-thumbnail",
    });

    setParticipantListVisible(!participantListVisible);
    setMenuOpen(false);
  };

  const handleScreenShareClick = () => {
    if (!screenshareInUse) {
      analyticsEvent({
        eventName: "user_action",
        actionType: "click",
        actionName: "start-screen-share",
      });
      toggleScreenShare();
      setMenuOpen(false);
    }
  };

  const togglePIP = () => {
    const active = hasPIP();
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: active
        ? "hide-picture-in-picture"
        : "show-picture-in-picture",
    });
    active ? stopPIP() : startPIP();
    setMenuOpen(false);
  };

  const handleCopyToClipboard = async () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "copy-waiting-room-link",
    });
    setIsCopied(true);
    const url = getRoomUrl();
    await navigator.clipboard.writeText(url);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <Wrapper ref={menuRef}>
      <ListGroup>
        {canFullscreen && !isMobile && (
          <ListItem icon={fullscreenIcon} onClick={handleFullscreenClick}>
            {isFullscreen() ? exitFullscreenText : fullscreenText}
          </ListItem>
        )}
        <ListItem
          flipIcon={participantListVisible}
          icon={participantListIcon}
          onClick={handleToggleThumbnailsClick}
        >
          {participantListVisible
            ? participantListVisibleCopy
            : participantListHiddenCopy}
        </ListItem>
        {canScreenShare && (
          <ListItem
            icon={isScreenSharing ? stopScreenshareIcon : screenshareIcon}
            onClick={handleScreenShareClick}
            disabled={screenshareInUse}
          >
            <span title={screenshareInUse ? screenshareInUseText : ""}>
              {isScreenSharing ? stopScreenshareText : screenshareText}
            </span>
          </ListItem>
        )}
        {pipEnabled() && (
          <ListItem icon={PIPIcon} onClick={togglePIP}>
            {hasPIP() ? disablePIP : enablePIP}
          </ListItem>
        )}
        {isTherapist && (
          <ListItem icon={copyIcon} onClick={handleCopyToClipboard}>
            {isCopied ? copiedToClipboardText : copyToClipboardText}
          </ListItem>
        )}
      </ListGroup>
      <ListGroup>
        <ListItem icon={settingsIcon} onClick={openMediaSettings}>
          {audioVideoCopy}
        </ListItem>
      </ListGroup>
    </Wrapper>
  );
};

export default SettingsMenu;
