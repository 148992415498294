import {
  WEBSOCKET_SET_CONNECT,
  WEBSOCKET_SET_CONNECTED,
  WEBSOCKET_SET_ERROR,
  SESSION_START,
  SESSION_END,
  WAITROOM_SET_PARTICIPANTS,
  WAITROOM_SET_DISPLAYNAME,
  WAITROOM_SET_THERAPIST_AVAILABLE,
  ADD_PARTICIPANT_INFO_FAILURE,
  ADD_PARTICIPANT_INFO_SUCCESS,
  ADD_PARTICIPANT_INFO_LOADING,
} from "./actions";

const reducer = (state, action) => {
  switch (action.type) {
    case WEBSOCKET_SET_CONNECT:
      return {
        ...state,
        websocket: {
          ...state.websocket,
          connect: action.connect,
        },
      };
    case WEBSOCKET_SET_CONNECTED:
      return {
        ...state,
        websocket: {
          ...state.websocket,
          isConnected: action.isConnected,
        },
      };
    case WEBSOCKET_SET_ERROR:
      return {
        ...state,
        websocket: {
          ...state.websocket,
          error: action.error,
        },
      };
    case WAITROOM_SET_DISPLAYNAME:
      return {
        ...state,
        displayName: action.displayName,
      };
    case WAITROOM_SET_PARTICIPANTS:
      return {
        ...state,
        therapist:
          action.participants.find(x => x.participantType === "therapist") ||
          {},
        waitingRoom: {
          ...state.waitingRoom,
          participants: action.participants,
        },
      };
    case WAITROOM_SET_THERAPIST_AVAILABLE:
      return {
        ...state,
        waitingRoom: {
          ...state.waitingRoom,
          therapistAvailable: action.available,
          therapistInitialState: action.isInitialState,
        },
      };
    case SESSION_START:
      return {
        ...state,
        session: {
          ...state.session,
          token: action.token,
          startTime: action.startTime,
          active: true,
          ended: false,
        },
      };
    case SESSION_END:
      return {
        ...state,
        session: {
          ...state.session,
          token: null,
          startTime: null,
          active: false,
          ended: true,
        },
      };
    case ADD_PARTICIPANT_INFO_SUCCESS:
      return {
        ...state,
        participantInfo: {
          ...state.participantInfo,
          [action.participantId]: {
            ...action.data,
            status: "success",
          },
        },
      };
    case ADD_PARTICIPANT_INFO_FAILURE:
      return {
        ...state,
        participantInfo: {
          ...state.participantInfo,
          [action.participantId]: {
            status: "error",
          },
        },
      };
    case ADD_PARTICIPANT_INFO_LOADING:
      return {
        ...state,
        participantInfo: {
          ...state.participantInfo,
          [action.participantId]: {
            status: "loading",
          },
        },
      };
    default:
      throw new Error("invalid action type");
  }
};

export default reducer;
