import React, { useState } from "react";
import styled from "styled-components";
import clockIcon from "../../assets/icon-clock.svg";
import useInterval from "../../hooks/useInterval";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.transparentGreyBackground};
  cursor: pointer;
  &:hover,
  &:active {
    background-color: ${({ theme }) =>
      theme.colors.transparentGreyBackgroundHover};
  }
`;

const TimeComponent = styled.div`
  display: inline-block;
  text-align: left;
  white-space: pre;
  padding: 10px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-variant-numeric: tabular-nums;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.large};
    padding: 10px 15px;
  }
`;

const Time = ({ data, ...rest }) => (
  <TimeComponent data={data} {...rest}>
    {(data.clock ? data.clock + "  |  " : "") + parseTime(data.elapsedTime)}
  </TimeComponent>
);

const TimeIcon = styled.div`
  background-image: url("${clockIcon}");
  background-size: 13px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 40px;
    background-size: 17px;
  }
`;

const parseTime = seconds => {
  if (!seconds) {
    return "00:00";
  }
  const date = new Date(0);
  date.setSeconds(seconds);
  if (seconds >= 3600) {
    return date.toISOString().substr(11, 8);
  }
  return date.toISOString().substr(14, 5);
};

const calcTime = d => {
  const now = new Date();
  const utcTimestamp = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds(),
  );
  return Math.floor(utcTimestamp / 1000) - d;
};

const getClock = () => {
  const now = new Date();
  return now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

function ElapsedTime({ isTherapist }) {
  const [timerExpanded, setTimerExpanded] = useState(true);
  const { state } = useAppStateContext();
  const {
    session: { startTime },
  } = state;
  const [data, setData] = useState({
    elapsedTime: startTime ? calcTime(startTime) : null,
    clock: startTime && isTherapist ? getClock() : null,
  });
  const { analyticsEvent } = useAnalyticsContext();

  useInterval(() => {
    if (startTime) {
      const timeSince = calcTime(startTime);
      const newClock = isTherapist ? getClock() : null;
      if (timeSince !== data.elapsedTime || newClock !== data.clock) {
        setData({
          elapsedTime: timeSince,
          clock: newClock,
        });
      }
    }
  }, 1000);

  const toggleTimer = expanded => {
    setTimerExpanded(expanded);

    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: expanded ? "timer-display-on" : "timer-display-off",
    });
  };

  return (
    <TimeWrapper>
      {timerExpanded ? (
        <Time onClick={() => toggleTimer(false)} data={data} />
      ) : (
        <TimeIcon onClick={() => toggleTimer(true)} />
      )}
    </TimeWrapper>
  );
}

export default ElapsedTime;
