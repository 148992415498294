import React from "react";
import styled from "styled-components";
import errorOccurredImage from "../../assets/error-occurred.svg";
import useCopy from "../../hooks/useCopy";
import Header from "../Header";
import BackgroundImage from "../BackgroundImage";
import Panel from "../Panel";
import { WaitingRoomProvider } from "../../providers/WaitingRoomProvider";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    margin: 5px 0;
  }
`;

const Message = styled.div`
  display: flex;
  background: url(${errorOccurredImage}) no-repeat;
  background-position: center top;
  padding-top: 130px;
  text-align: center;
  width: 300px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    text-align: left;
    padding-top: 0;
    align-items: center;
    width: 600px;
    height: 150px;
    background-position: right;
  }
`;

const UnknownError = () => {
  const [errorPageTitleText, errorPageMessageText] = useCopy([
    "errorpage.title",
    "errorpage.message",
  ]);
  return (
    <WaitingRoomProvider>
      <Header type="logoOnly" />
      <BackgroundImage />
      <Wrapper>
        <Panel>
          <Message>
            <div>
              <h4>{errorPageTitleText}</h4>
              <div>{errorPageMessageText}</div>
            </div>
          </Message>
        </Panel>
      </Wrapper>
    </WaitingRoomProvider>
  );
};

export default UnknownError;
