import React, { useEffect } from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { Button } from "@sussex/react-kit/elements";
import loadingIcon from "../../assets/loading.svg";
import { requestEndSession } from "../../api/httpapi";
import { datadogEvent } from "../../datadog";

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.default};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Inner = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.colors.default};
  color: ${({ theme }) => theme.colors.light};
  border: 1px solid ${({ theme }) => theme.colors.default};
  border-radius: 30px;
  min-width: 100px;
  margin-top: 15px;
  &:hover {
    background: black;
  }
`;

const SessionStarting = ({ isTherapist, reconnecting }) => {
  const [reconnectingText, startingText, waitingForClientText, cancelText] =
    useCopy([
      "dashboard.reconnectingMessage",
      "dashboard.startingMessage",
      "session.waitingForClient",
      "global.cancel",
    ]);

  const endSession = reason => {
    requestEndSession();

    datadogEvent({
      category: "session",
      event: "ended",
      component: "SessionStarting",
      metadata: {
        reason: reason,
      },
    });
  };

  // Set a 60 second timeout
  useEffect(() => {
    if (!isTherapist || reconnecting) {
      return;
    }

    const startSessionTimeout = setTimeout(() => {
      endSession("timeout");
    }, 60 * 1000);

    return () => {
      clearTimeout(startSessionTimeout);
    };
  }, [isTherapist, reconnecting]);

  return (
    <Wrapper>
      <Inner>
        <Text>
          <Icon src={loadingIcon} alt="loading" />
          {reconnecting
            ? reconnectingText
            : isTherapist
            ? waitingForClientText
            : startingText}
        </Text>
        {isTherapist && !reconnecting && (
          <CancelButton
            variant="light"
            onClick={() => {
              endSession("cancel");
            }}
          >
            {cancelText}
          </CancelButton>
        )}
      </Inner>
    </Wrapper>
  );
};

export default SessionStarting;
