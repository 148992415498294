import React, { useState, useCallback, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import AddButton from "./AddButton";
import { TextArea } from "@sussex/react-kit/elements";
import sendIcon from "../../assets/send-button.svg";
import inactiveSendIcon from "../../assets/inactive-send-button.svg";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const lineHeight = 20;

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: flex-end;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 2px;
`;

const InputWrapper = styled.form`
  position: relative;
  width: 100%;
`;

const Input = styled(TextArea)`
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  padding-right: 40px;
  border-radius: 30px;
  height: auto;
  min-height: auto;
  line-height: ${lineHeight}px;
  font-size: 16px;
  resize: none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
    ${({ disabled }) =>
      disabled
        ? css`
            opacity: 0.5;
          `
        : css`
            opacity: 1;
          `}
  }
`;

const SendButton = styled.button`
  position: absolute;
  width: 21px;
  height: 18px;
  right: 10px;
  bottom: 10px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  appearance: none;
  border: 0;
  outline: none;
  background-color: transparent;
  ${({ disabled }) =>
    disabled
      ? css`
          background-image: url("${inactiveSendIcon}");
        `
      : css`
          cursor: pointer;
          background-image: url("${sendIcon}");
        `}
`;

export default function ChatFooter({
  placeholder,
  disabled,
  disabledTooltip,
  onSend,
  onClose,
  onAddClicked,
  addDisabled,
  inSession,
}) {
  const [message, setMessage] = useState("");
  const footerRef = useRef(null);
  const inputRef = useRef(null);
  const { analyticsEvent } = useAnalyticsContext();

  const sendDisabled = disabled || message.trim() === "";

  const handleInput = e => {
    const input = e.target;
    // restrict message to 500 characters:
    let value = input.value;
    if (value.length > 500) {
      value = value.substring(0, 500);
    }
    setMessage(value);

    // set textarea height:
    input.style.height = "auto";
    if (input.scrollHeight > input.offsetHeight) {
      input.style.height = input.scrollHeight + "px";
    }
  };

  const handleSend = useCallback(() => {
    if (sendDisabled) return;
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "send-message",
    });
    onSend(message);
    setMessage("");
    const input = inputRef.current;
    if (input) {
      input.style.height = "auto";
      input.focus();
    }
  }, [message, onSend, sendDisabled, analyticsEvent]);

  const handleKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  // Prevent scrolling body when touchmove fired on chat footer.
  // Addresses issue where iOS does not respect `overflow: hidden`
  // when virtual keyboard is open and allows scrolling.
  useEffect(() => {
    const footer = footerRef.current;
    if (!footer) return;
    const preventScroll = e => {
      e.preventDefault();
    };

    footer.addEventListener("touchmove", preventScroll, { passive: false });
    return () => {
      footer.removeEventListener("touchmove", preventScroll, {
        passive: false,
      });
    };
  }, []);

  return (
    <Wrapper ref={footerRef}>
      {onAddClicked && (
        <ButtonWrapper>
          <AddButton
            disabled={addDisabled}
            onClick={() => {
              onClose();
              onAddClicked();
            }}
            inSession={inSession}
          />
        </ButtonWrapper>
      )}

      <Tooltip title={disabled && disabledTooltip ? disabledTooltip : ""}>
        <InputWrapper
          onSubmit={e => {
            e.preventDefault();
            handleSend();
          }}
        >
          <Input
            rows="1"
            placeholder={placeholder}
            value={message}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            disabled={disabled}
          />
          <SendButton
            type="submit"
            disabled={sendDisabled}
            id="sendWaitingRoomChatMessage"
          />
        </InputWrapper>
      </Tooltip>
    </Wrapper>
  );
}
