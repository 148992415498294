import React, { useState, createContext, useContext, useCallback } from "react";
import { ThemeProvider } from "styled-components";
import { themeDefault, themeBackgroundImage } from "../../theme";

export const THEME_DEFAULT = "default";
export const THEME_BACKGROUND_IMAGE = "background-image";

export const SessionsThemeContext = createContext({});

export function SessionsThemeProvider({ children }) {
  const [theme, setTheme] = useState(themeDefault);

  const changeTheme = useCallback(themeName => {
    const newTheme =
      themeName === THEME_DEFAULT ? themeDefault : themeBackgroundImage;
    setTheme(newTheme);
  }, []);

  return (
    <SessionsThemeContext.Provider
      value={{
        changeTheme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </SessionsThemeContext.Provider>
  );
}

export function useSessionsThemeContext() {
  const context = useContext(SessionsThemeContext);
  if (!context) {
    throw new Error(
      "useSessionsThemeContext must be used within a SessionsThemeProvider",
    );
  }

  return context;
}
