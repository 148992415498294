import React from "react";
import styled from "styled-components";
import { SlideoutPanel, Panel } from "../SlideoutLayout";
import ChatHeader from "./ChatHeader";
import ChatLog from "./ChatLog";
import ChatFooter from "./ChatFooter";
import { sendChatMessage } from "../../api/httpapi";
import EventBus from "../../eventBus";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import useWindowSize from "../../hooks/useWindowSize";
import { useChatContext } from "./ChatProvider";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ height }) => height + "px"};
`;

const BodyWrap = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

const FooterWrap = styled.div`
  width: 100%;
  background-color: white;
`;

const Message = styled.div`
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  margin: 0 20px;
`;

export default function WaitingRoomChat({
  isTherapist,
  participant,
  channel,
  onAdd,
  addDisabled,
  onBack,
  onClose,
  position,
  overlap,
  inSession,
}) {
  const { state } = useAppStateContext();
  const { getMessages, isActiveChannel, setActiveChannel } = useChatContext();
  const { room: waitingRoom } = useWaitingRoomContext();
  const [therapistUnavailable, inputPlaceholder, therapistTooltip] = useCopy([
    "chat.therapistUnavailable",
    "chat.inputPlaceholder",
    "chat.disabledUnavailable",
  ]);

  const isOpen = isActiveChannel(channel);
  const { height } = useWindowSize();
  const chatLog = getMessages(channel);
  const participantId = participant === null ? "" : participant.participantId;
  const { displayName } = useParticipantInfo(participantId);

  const onSend = async m => {
    try {
      const sent = sendChatMessage(participant.participantId, m);

      if (sent) {
        EventBus.emit("chat", {
          from: state.displayName,
          channel: channel,
          message: m,
          self: true,
        });
      }
    } catch (err) {
      console.error("Could not send chat message", err);
    }
  };

  const handleClose = () => {
    setActiveChannel("");
    onClose && onClose();
  };

  if (participant === null) {
    return null;
  }

  const placeholder = swapCopyVariables(inputPlaceholder, {
    NAME: isTherapist ? displayName : waitingRoom.therapistDisplayName,
  }).join("");

  const disabledTooltip = isTherapist
    ? therapistTooltip
    : swapCopyVariables(therapistUnavailable, {
        NAME: displayName,
      }).join("");

  const PanelType = onBack ? Panel : SlideoutPanel;

  return (
    <PanelType
      active={isOpen}
      onClose={handleClose}
      header={() => (
        <ChatHeader isTherapist={isTherapist} participant={participant} />
      )}
      overlap={overlap}
      position={position}
      onBack={onBack}
    >
      <Wrap height={height - 82}>
        <BodyWrap>
          {!isTherapist &&
            !state.waitingRoom.therapistAvailable &&
            chatLog.length === 0 && (
              <Message>
                {swapCopyVariables(therapistUnavailable, {
                  NAME: displayName,
                })}
              </Message>
            )}
          {(isTherapist ||
            state.waitingRoom.therapistAvailable ||
            chatLog.length > 0) && (
            <ChatLog
              isTherapist={isTherapist}
              participant={participant}
              messages={chatLog}
            />
          )}
        </BodyWrap>
        <FooterWrap>
          <ChatFooter
            placeholder={placeholder}
            addDisabled={addDisabled}
            disabled={!state.waitingRoom.therapistAvailable}
            disabledTooltip={disabledTooltip}
            onSend={onSend}
            onClose={handleClose}
            onAddClicked={onAdd}
            inSession={inSession}
          />
        </FooterWrap>
      </Wrap>
    </PanelType>
  );
}
