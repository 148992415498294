import React, { createContext, useState, useEffect, useRef } from "react";
import AttachVisibilityHandler from "./AttachVisibilityHandler/AttachVisibilityHandler";
import useHandleRoomDisconnectionErrors from "./useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors";
import useHandleTrackPublicationFailed from "./useHandleTrackPublicationFailed/useHandleTrackPublicationFailed";
import useLocalTracks from "./useLocalTracks/useLocalTracks";
import useRoom from "./useRoom/useRoom";
import useParticipants from "./useParticipants/useParticipants";
import useScreenShareToggle from "./useScreenShareToggle/useScreenShareToggle";
import useScreenShareParticipant from "./useScreenShareParticipant/useScreenShareParticipant";
import useDataTrack from "./useDataTrack/useDataTrack";
import useSelectedParticipant from "./useSelectedParticipant/useSelectedParticipant";
import { isSupported } from "twilio-video";
import { getFallbackLanguage } from "../CopyProvider";
import EventBus from "../../eventBus";
import { datadogSetSessionData } from "../../datadog";

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export const VideoContext = createContext(null);

export function VideoProvider({ children }) {
  if (!isSupported) {
    const lang = getFallbackLanguage(navigator.language);
    window.location.href = `/upgrade/${lang}.html`;
  }

  const [connectFailed, setConnectFailed] = useState(false);
  const [connectionDropped, setConnectionDropped] = useState(false);
  const [primaryVideoTrack, setPrimaryVideoTrack] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Error handler for first connection attempt
  const onConnectError = error => {
    if (!isMounted.current) {
      return;
    }
    setConnectFailed(true);
    console.error("Twilio connection error", error);
  };

  // Error handler for an in-progress session disconnection
  const onDisconnectError = error => {
    if (isMounted.current && error) {
      setConnectionDropped(true);
      console.error("Twilio room disconnected with error", error);
    }
  };

  // Error handler for screen sharing
  const onScreenShareError = error => {
    console.error("Screen share error", error);
  };

  // Error handler for toggling track publications
  const onTrackPublicationError = error => {
    console.error("Track publication error", error);
  };

  const {
    localTracks,
    dataTrack,
    createLocalAudioTrack,
    removeLocalAudioTrack,
    createLocalVideoTrack,
    removeLocalVideoTrack,
    replaceLocalVideoTrack,
    replaceLocalAudioTrack,
    isAcquiringLocalTracks,
    localVideoOptions,
    localTrackError,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(localTracks, onConnectError);
  const { sendMessage } = useDataTrack(EventBus, room, dataTrack);
  const { selectedParticipant, setSelectedParticipant } =
    useSelectedParticipant(room);
  const { toggleScreenShare, isScreenSharing } = useScreenShareToggle(
    room,
    onScreenShareError,
  );
  const screenShareParticipant = useScreenShareParticipant(
    room,
    setSelectedParticipant,
  );
  const participants = useParticipants(room, isConnecting);

  useEffect(() => {
    datadogSetSessionData({
      videoRoomName: room?.name,
      videoRoomSID: room?.sid,
      identity: room?.localParticipant?.participantId,
    });
  }, [room]);

  useHandleRoomDisconnectionErrors(
    room,
    onDisconnectError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isScreenSharing,
    toggleScreenShare,
    setSelectedParticipant,
  );
  useHandleTrackPublicationFailed(room, onTrackPublicationError);

  return (
    <VideoContext.Provider
      value={{
        room,
        sendMessage,
        isConnecting,
        participants,
        localTracks,
        createLocalAudioTrack,
        createLocalVideoTrack,
        removeLocalVideoTrack,
        replaceLocalVideoTrack,
        replaceLocalAudioTrack,
        isAcquiringLocalTracks,
        localVideoOptions,
        localTrackError,
        connect,
        connectFailed,
        connectionDropped,
        isScreenSharing,
        toggleScreenShare,
        screenShareParticipant,
        selectedParticipant,
        setSelectedParticipant,
        primaryVideoTrack,
        setPrimaryVideoTrack,
      }}
    >
      {children}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
