import React, { useCallback, useEffect, useState } from "react";
import Session from "../pages/SessionView";
import ConnectionDroppedHandler from "../components/ConnectionDroppedHandler";
import Dashboard from "../components/Dashboard";
import { SessionMenuProvider } from "../providers/SessionMenuProvider";
import { DevicesProvider } from "../providers/DevicesProvider";
import { AudioAlertProvider } from "../providers/AudioAlertProvider";
import { VideoProvider } from "../providers/VideoProvider";
import useVideoContext from "../hooks/useVideoContext";
import { useAppStateContext, actions } from "../providers/AppStateProvider";
import { useChatContext } from "../components/Chat";

const { SESSION_END } = actions;

const VideoView = ({ setSessionId }) => {
  const { connectFailed } = useVideoContext();
  const { dispatch, state } = useAppStateContext();
  const { session } = state;
  const { setActiveChannel } = useChatContext();

  useEffect(() => {
    if (connectFailed) {
      dispatch({ type: SESSION_END });
    }
  }, [connectFailed, dispatch]);

  const onSessionEnd = useCallback(
    (room, error) => {
      if (room?.sid) {
        setSessionId(room.sid);
      }
      if (!error) {
        setActiveChannel("");
      }
    },
    [setActiveChannel, setSessionId],
  );

  return !session.active ? (
    <Dashboard connectFailed={connectFailed} />
  ) : (
    <Session
      token={session.token}
      playNotification={false}
      isTherapist={true}
      onSessionEnd={onSessionEnd}
    />
  );
};

const TherapistRoomView = () => {
  // reset VideoProvider after each session
  const [sessionId, setSessionId] = useState("");

  return (
    <VideoProvider key={sessionId}>
      <DevicesProvider>
        <ConnectionDroppedHandler isTherapist={true}>
          <AudioAlertProvider>
            <SessionMenuProvider>
              <VideoView setSessionId={setSessionId} />
            </SessionMenuProvider>
          </AudioAlertProvider>
        </ConnectionDroppedHandler>
      </DevicesProvider>
    </VideoProvider>
  );
};

export default TherapistRoomView;
