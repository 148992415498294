import React from "react";
import styled from "styled-components";
import { TextInput } from "@sussex/react-kit/elements";
import useWindowSize from "../../hooks/useWindowSize";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-wrap: nowrap;
  }
`;

const Field = styled.div`
  width: 100%;
`;

const Input = styled(TextInput)`
  border: 1px solid #dddddd;
`;

function RoomAlias({ roomAlias, error, onChange }) {
  const { width } = useWindowSize();

  return (
    <Wrapper>
      <Field>
        <Input
          disabled
          length={window.location.hostname.length}
          value={`${window.location.hostname}/`}
          stacked={width < 768}
          adjacent={width >= 768}
        />
      </Field>
      <Field>
        <Input
          value={roomAlias}
          onChange={onChange}
          error={error}
          stacked={width < 768}
          adjacent={width >= 768}
        />
      </Field>
    </Wrapper>
  );
}

export default RoomAlias;
