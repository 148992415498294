import React from "react";
import styled from "styled-components";
import { Checkbox } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const ClientTimerWrapper = styled.div`
  line-height: 1;
  span {
    margin-left: 10px;
    font-size: 16px;
  }
`;

function ClientTimerSettings({ onChange, clientTimer }) {
  const [clientTimerLabel] = useCopy(["settings.clientTimer"]);
  const { analyticsEvent } = useAnalyticsContext();

  const handleClientTimerChange = e => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: clientTimer
        ? "disable-session-timer"
        : "enable-session-timer",
    });
    onChange({ clientTimer: !clientTimer });
  };

  return (
    <ClientTimerWrapper>
      <Checkbox checked={clientTimer} onClick={handleClientTimerChange} />
      <span>{clientTimerLabel}</span>
    </ClientTimerWrapper>
  );
}

export default ClientTimerSettings;
