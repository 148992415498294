import React from "react";
import { datadogEvent } from "../../datadog";
import useCopy from "../../hooks/useCopy";
import ConfirmationModal from "../ConfirmationModal";
import { requestEndSession } from "../../api/httpapi";

export default function ExitConfirmation({ onClose }) {
  const [confirmEndSessionText, confirmEndSessionTitle] = useCopy([
    "callscreen.confirmEndSession",
    "callscreen.confirmEndSessionTitle",
  ]);

  const endSession = async () => {
    datadogEvent({
      category: "session",
      feature: "end",
      event: "clicked",
      component: "VideoSession.ExitConfirmation",
    });

    const ended = await requestEndSession();

    if (ended) {
      datadogEvent({
        category: "session",
        event: "ended",
        component: "VideoSession.ExitConfirmation",
      });
    }
  };
  return (
    <ConfirmationModal
      title={confirmEndSessionTitle}
      onConfirm={endSession}
      onClose={onClose}
    >
      {confirmEndSessionText}
    </ConfirmationModal>
  );
}
