import React from "react";
import styled, { css } from "styled-components";
import { Button } from "@sussex/react-kit/elements";
import startSpinner from "../../assets/start-spinner.svg";

const Btn = styled(Button)`
  min-width: 0;
  padding: 5px 14px;
  font-size: 13px;
  position: relative;
  vertical-align: middle;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: inherit;
  }
`;

const Text = styled.span`
  ${({ loading }) =>
    loading &&
    css`
      visibility: hidden;
    `}
`;

const Spinner = styled.img`
  display: none;
  ${({ loading }) =>
    loading &&
    css`
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

function StartButton({
  onClick,
  full,
  participantId,
  disabled,
  buttonText,
  loading,
}) {
  return (
    <Btn
      full={full}
      onClick={onClick}
      variant="blue"
      id={`startSession-${participantId}`}
      disabled={disabled}
      loading={loading ? 1 : 0}
      retainWidthOnMobile
    >
      <Text loading={loading ? 1 : 0}>{buttonText}</Text>
      <Spinner loading={loading ? 1 : 0} src={startSpinner} />
    </Btn>
  );
}

export default StartButton;
