import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { requestAddParticipants } from "../../api/httpapi";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { useInSessionWaitingRoomContext } from "./InSessionWaitingRoomProvider";
import useVideoContext from "../../hooks/useVideoContext";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import AddToSessionModal from "./AddToSessionModal";
import { SlideoutPanel } from "../SlideoutLayout";
import { H2 } from "@sussex/react-kit/elements";
import ClientItem from "./ClientItem";
import TherapistAvailability from "../TherapistAvailability";
import { WaitingRoomChat, useChatContext } from "../Chat";
import { datadogEvent } from "../../datadog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Body = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex: 1 0 auto;
`;

const ClientWrapper = styled.div`
  padding: 20px 0;
  margin: 0 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const AtCapacity = styled.div`
  margin: 0 18px;
  padding: 20px 0;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: left;
`;

const Empty = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const FooterWrapper = styled.div`
  align-self: flex-end;
  padding: 20px;
  width: calc(100% - 40px);
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: -1px 0 0;
  text-align: left;
  font-weight: normal;
`;

export default function InSessionWaitingRoom({
  waitingRoomOpen,
  setWaitingRoomOpen,
}) {
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants } = waitingRoom;
  const [seenParticipants, setSeenParticipants] = useState(participants);
  const { setNotification } = useInSessionWaitingRoomContext();
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [loadingParticipantId, setLoadingParticipantId] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const { participants: inSessionParticipants } = useVideoContext();
  const maxParticipants = process.env.REACT_APP_MAX_PARTICIPANTS - 1;
  const { setActiveChannel, getUnreadCount, isActiveChannel } =
    useChatContext();

  const [titleText, fullText, emptyText] = useCopy([
    "sessioncontrols.waitingroom.title",
    "sessioncontrols.waitingroom.full",
    "sessioncontrols.waitingroom.emptyRoom",
  ]);

  const atCapacity =
    maxParticipants && inSessionParticipants.length === maxParticipants;
  const empty = participants.length === 0;

  const closePanel = () => {
    setWaitingRoomOpen(false);
  };

  const closeChat = useCallback(() => {
    if (chatOpen) {
      setChatOpen(false);
    }
  }, [chatOpen]);

  const handleAddClick = (participant, shouldClosePanel, componentName) => {
    datadogEvent({
      category: "session",
      feature: "add_participant",
      event: "clicked",
      component: `InSessionWaitingRoom.${componentName}`,
      metadata: {
        participant: participant.participantId,
      },
    });

    setSelectedParticipant(participant);
    setConfirmationOpen(true);

    if (shouldClosePanel) {
      closeChat();
      closePanel();
    }
  };

  const addToSession = async () => {
    const participantId = selectedParticipant.participantId;

    setLoadingParticipantId(participantId);
    const added = await requestAddParticipants([participantId]);

    if (added) {
      datadogEvent({
        category: "session",
        event: "participant_added",
        component: "InSessionWaitingRoom",
        metadata: {
          participant: participantId,
        },
      });
    }

    setLoadingParticipantId("");
    setConfirmationOpen(false);
  };

  // Track seen participants while waiting room is open.
  useEffect(() => {
    if (waitingRoomOpen) {
      setSeenParticipants(prev =>
        prev.concat(participants.map(p => p.participantId)),
      );

      const chatParticipantFound = participants.find(p =>
        isActiveChannel(p.participantId),
      );

      if (!chatParticipantFound) {
        closeChat(false);
      }
    }
  }, [participants, waitingRoomOpen, isActiveChannel, closeChat]);

  // Increment unseen count while waiting room is closed.
  useEffect(() => {
    if (!waitingRoomOpen) {
      const newParticipants = participants.reduce((accum, curr) => {
        if (!seenParticipants.includes(curr.participantId)) {
          return accum + 1;
        }
        return accum;
      }, 0);
      const unreadMessages = participants.reduce((accum, curr) => {
        return accum + getUnreadCount(curr.participantId);
      }, 0);
      setNotification(newParticipants + unreadMessages > 0);
    }
  }, [
    waitingRoomOpen,
    setNotification,
    seenParticipants,
    participants,
    getUnreadCount,
  ]);

  // Reset unseen count on close.
  useEffect(() => {
    if (waitingRoomOpen) {
      setNotification(false);
    }
  }, [waitingRoomOpen, setNotification]);

  const chatParticipant =
    participants.filter(p => {
      return isActiveChannel(p.participantId);
    })[0] || null;

  return (
    <>
      <SlideoutPanel
        active={waitingRoomOpen}
        onClose={() => {
          closeChat();
          closePanel();
        }}
        position="left"
        header={() => <H2>{titleText}</H2>}
        overlap={false}
        childPanelActive={chatOpen}
        childPanelCleanup={() => {
          if (
            chatParticipant &&
            isActiveChannel(chatParticipant.participantId)
          ) {
            setActiveChannel("");
          }
        }}
        childPanel={
          <WaitingRoomChat
            isTherapist={true}
            participant={chatParticipant}
            channel={chatParticipant ? chatParticipant.participantId : ""}
            position="left"
            overlap={false}
            onAdd={() =>
              handleAddClick(chatParticipant, false, "WaitingRoomChat")
            }
            addDisabled={atCapacity}
            onClose={() => {
              closeChat(false);
              closePanel();
            }}
            onBack={closeChat}
            inSession={true}
          />
        }
      >
        <Container>
          <Body>
            {participants.map(p => (
              <ClientWrapper key={p.participantId}>
                <ClientItem
                  participant={p}
                  onAdd={() => handleAddClick(p, true, "ClientItem")}
                  addDisabled={atCapacity}
                  onChat={() => {
                    setActiveChannel(p.participantId);
                    setChatOpen(true);
                  }}
                  chatDisabled={false}
                  loading={loadingParticipantId === p.participantId}
                />
              </ClientWrapper>
            ))}
            {empty && <Empty>{emptyText}</Empty>}
            {!empty && atCapacity && (
              <AtCapacity>
                {swapCopyVariables(fullText, {
                  MAX: maxParticipants,
                })}
              </AtCapacity>
            )}
          </Body>
          <FooterWrapper>
            <TherapistAvailability isTherapist={true} photoSize="50px" />
          </FooterWrapper>
        </Container>
      </SlideoutPanel>
      {confirmationOpen && (
        <AddToSessionModal
          onConfirm={addToSession}
          onClose={() => setConfirmationOpen(false)}
          participant={selectedParticipant}
        />
      )}
    </>
  );
}
