import { theme } from "@sussex/react-kit";
import { createTheme } from "@mui/material/styles";

const sessionsTheme = {
  ...theme,
  breakpoints: {
    tablet: "768px",
    tabletInt: 768,
    desktop: "1000px",
    desktopInt: 1000,
    xl: "1200px",
    xlInt: 1200,
  },
};

const themeDefault = createTheme({
  ...sessionsTheme,
  header: {
    background: theme.colors.primaryBlue,
    height: "70px",
  },
  sidebar: {
    background: "none",
  },
  nav: {
    fontColor: theme.colors.default,
    itemHeight: "100%",
  },
  panel: {
    boxShadow: "none",
    border: `1px solid ${theme.colors.border}`,
  },
  footer: {
    background: "none",
  },
  ptlogo: {
    color: "#8A8D96",
  },
});

const themeBackgroundImage = createTheme({
  ...sessionsTheme,
  header: {
    background: `
      linear-gradient(
        180deg,
        rgba(44, 45, 48, 0.75) 0%,
        rgba(196, 196, 196, 0) 100%
      )
    `,
    height: "100px",
  },
  sidebar: {
    background: `
      linear-gradient(
        90deg,
        rgba(44, 45, 48, 50%),
        rgba(196, 196, 196, 0)
      )
    `,
  },
  nav: {
    fontColor: theme.colors.white,
    itemHeight: "30px",
  },
  panel: {
    boxShadow: "0px 0px 20px rgba(52, 63, 83, 0.3)",
    border: "none",
  },
  footer: {
    background: `
      linear-gradient(
        0deg,
        rgba(45, 45, 48, 0.75) 0%,
        rgba(45, 45, 48, 0) 100%
      )
    `,
  },
  ptlogo: {
    color: theme.colors.white,
  },
});

export { themeDefault, themeBackgroundImage };
