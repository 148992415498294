import React from "react";
import styled from "styled-components";
import { Button } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import chatIcon from "../../assets/chat-icon.svg";
import { useChatContext } from "../Chat";
import { useAppStateContext } from "../../providers/AppStateProvider";
import Tooltip from "@mui/material/Tooltip";

const Btn = styled(Button)`
  min-width: 45px;
  padding: 5px 14px;
  margin-right: 10px;
  font-size: 13px;
  position: relative;
  vertical-align: middle;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: inherit;
  }
`;

const ChatIcon = styled.img`
  width: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Counter = styled.div`
  position: absolute;
  top: 2px;
  left: 8px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  font-size: 9px;
  line-height: 15px;
  text-align: center;
  z-index: 1;
`;

function ChatButton({ channel, disabled }) {
  const {
    state: { waitingRoom },
  } = useAppStateContext();

  const { activeChannel, setActiveChannel, getUnreadCount } = useChatContext();
  const [chatText] = useCopy(["sessioncontrols.chat"]);

  const unreadCount = getUnreadCount(channel);

  const onClick = () => {
    // Set the active channel after the slideout panel invokes the onClose of the replaced chat panel.
    setActiveChannel("");

    if (activeChannel !== channel) {
      setTimeout(() => {
        setActiveChannel(channel);
      });
    }
  };

  if (!waitingRoom.therapistAvailable) {
    return null;
  }

  if (disabled) {
    return (
      <Btn variant="blue" disabled={true} retainWidthOnMobile>
        <ChatIcon src={chatIcon} />
      </Btn>
    );
  }

  return (
    <Tooltip title={chatText}>
      <Btn
        onClick={onClick}
        variant="blue"
        id={`chat-${channel}`}
        retainWidthOnMobile
      >
        {unreadCount > 0 && <Counter>{unreadCount}</Counter>}
        <ChatIcon src={chatIcon} />
      </Btn>
    </Tooltip>
  );
}

export default ChatButton;
