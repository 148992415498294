import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { useAudioAlertContext } from "../../providers/AudioAlertProvider";
import { useAppStateContext } from "../../providers/AppStateProvider";
import ClientItem from "./ClientItem";

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.secondary};
`;

const ListItemWrapper = styled.div`
  width: 100%;
  margin-top: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 0;
    & + & {
      margin-top: 10px;
    }
  }
`;

function ClientList({
  chatDisabled,
  startDisabled,
  startSession,
  loadingIdentity,
}) {
  const [startText, waitingText] = useCopy([
    "global.start",
    "dashboard.waitingForClient",
  ]);
  const [seenParticipants, setSeenParticipants] = useState([]);
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants: waitingParticipants } = waitingRoom;
  const { playChime } = useAudioAlertContext();

  // this ref tracks participants as "seen" when they show up to a room
  useEffect(() => {
    const newUnseenParticipants = waitingParticipants.filter(
      p =>
        !seenParticipants.map(s => s.participantId).includes(p.participantId),
    );
    if (newUnseenParticipants.length > 0) {
      setSeenParticipants(prev => prev.concat(newUnseenParticipants));
      playChime();
    }
  }, [waitingParticipants, seenParticipants, playChime]);

  const isEmpty = waitingParticipants.length === 0;

  const renderListElement = (p, index) => {
    return (
      <ListItemWrapper key={p.participantId}>
        <ClientItem
          participant={p}
          onStart={() => startSession(p)}
          startDisabled={startDisabled || isEmpty || loadingIdentity}
          loading={loadingIdentity === p.participantId}
          buttonText={startText}
          chatVisible={true}
          chatDisabled={chatDisabled || isEmpty || loadingIdentity}
          index={index}
          isGhost={p.isGhost}
        />
      </ListItemWrapper>
    );
  };

  return (
    <List>
      {isEmpty
        ? renderListElement({
            identity: "waiting-placeholder",
            displayName: waitingText,
            photoUrl: "",
            isGhost: true,
          })
        : waitingParticipants.map(renderListElement)}
    </List>
  );
}

export default ClientList;
