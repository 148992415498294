import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { SlideoutProvider, useSlideoutContext } from "./SlideoutProvider";
import { transitionTime, panelWidth } from "./SlideoutConsts";

const MainContent = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    position: relative;
    transition: all ${transitionTime}ms ease-in-out;
    margin: 0;

    ${({ activePanel, overlap, panelPosition }) => {
      if (activePanel && !overlap) {
        return panelPosition === "right"
          ? css`
              width: calc(100% - ${panelWidth});
              margin-right: ${panelWidth};
            `
          : css`
              width: calc(100% - ${panelWidth});
              margin-left: ${panelWidth};
            `;
      }
    }};
  }
`;

const View = ({ children }) => {
  const panelRef = useRef(null);
  const { panelPortalRef, activePanel } = useSlideoutContext();

  useEffect(() => {
    panelPortalRef.current = panelRef.current;
  }, [panelPortalRef]);

  return (
    <>
      <div ref={panelRef} />
      <MainContent
        activePanel={activePanel}
        overlap={activePanel && activePanel.overlap}
        panelPosition={activePanel && activePanel.position}
      >
        {children}
      </MainContent>
    </>
  );
};

export default function SlideoutLayout(props) {
  return (
    <SlideoutProvider>
      <View {...props} />
    </SlideoutProvider>
  );
}
