import React from "react";
import AvailableBackgrounds from "../../backgrounds";
import styled from "styled-components";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";

const Styled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: ${({ background }) =>
    background
      ? `url("/backgrounds/full/${background}")`
      : "linear-gradient( rgb(236, 241, 251) 0%, rgba(245, 247, 249, 0) 100%) left top no-repeat, rgb(255, 255, 255)"};
  background-size: cover;
  background-position: center center;
`;

const BackgroundImage = () => {
  const { room } = useWaitingRoomContext();
  return <Styled background={AvailableBackgrounds[room.background]} />;
};

export default BackgroundImage;
